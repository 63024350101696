import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';

import { PermissionsService } from '../permissions/permissions.service';

@Injectable()
export class SystemControlGuardService implements CanActivate {
  constructor(
    private router: Router,
    private permissionsService: PermissionsService,
  ) {}

  public canActivate(): boolean | UrlTree {
    return (
      this.permissionsService.checkPermission({ name: 'system_control' }) ||
      this.router.parseUrl('/error/403')
    );
  }
}

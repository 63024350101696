<div class="page-header-nav">
  <div class="page-header-nav__box">
    <a
      *ngFor="let item of breadcrumbs"
      class="page-header-nav__breadcrumb"
      [routerLink]="item.url"
      [queryParams]="item.query_params"
    >
      {{ item.label }}
    </a>

    <span class="page-header-nav__current">
      {{ page_title }}
    </span>
  </div>

  <div *ngIf="tabs?.length" class="page-header-nav__tabs">
    <a
      *ngFor="let tab of tabs"
      class="page-header-nav__tabs-item"
      [class.page-header-nav__tabs-item--active]="tab.active"
      [routerLink]="tab.url"
      [queryParams]="tab.query_params"
    >
      <ng-container *ngIf="tab.label">
        {{ tab.label }}
      </ng-container>
      <mat-icon *ngIf="tab.icon" class="button__icon">
        {{ tab.icon }}
      </mat-icon>
    </a>
  </div>

  <mat-form-field
    *ngIf="select"
    class="form-field form-field--select"
    floatLabel="never"
    hideRequiredMarker="true"
  >
    <mat-select
      class="form-select"
      [formControl]="select.control"
      [disabled]="select.disabled || disabled_elements.get('select')"
    >
      <mat-option *ngFor="let option of select.options" [value]="option.value">
        {{ option.label }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>

<div *ngIf="search || actions?.length" class="page-header-box">
  <mat-form-field
    *ngIf="search"
    class="page-header-search form-field"
    [style.width.px]="search.width"
  >
    <mat-icon class="page-header-search__icon" matPrefix>
      search
    </mat-icon>

    <input
      *ngIf="!search.autocomplete"
      #searchInput
      type="search"
      matInput
      [formControl]="search.control"
      [placeholder]="search.placeholder"
    />

    <ng-container *ngIf="search.autocomplete?.enabled">
      <input
        #searchInput
        type="search"
        matInput
        [placeholder]="search.placeholder"
        [value]="search.autocomplete.value"
        [matAutocomplete]="autocomplete"
        (input)="autocompleteSearch($event.target.value)"
      />

      <mat-autocomplete #autocomplete (closed)="autocompleteClosed(searchInput.value)">
        <mat-optgroup
          *ngFor="let group of autocomplete_options"
          [label]="'general:placeholder_' + group.title | i18next"
        >
          <mat-option *ngFor="let label of group.options" [value]="label">
            {{ label }}
          </mat-option>
        </mat-optgroup>
      </mat-autocomplete>
    </ng-container>
  </mat-form-field>

  <div *ngIf="actions?.length" class="page-header-actions">
    <ng-container *ngFor="let item of actions" [ngSwitch]="item.type">
      <button
        *ngSwitchCase="'button'"
        class="button"
        [class.button--alt]="item.label"
        mat-raised-button
        [color]="item.color"
        [matTooltip]="item.tooltip"
        [disabled]="disabled_elements.get(item.name)"
        (click)="click(item.name)"
      >
        <ng-container *ngTemplateOutlet="itemBody"></ng-container>
      </button>

      <button
        *ngSwitchCase="'mat-menu-button'"
        class="button"
        [class.button--alt]="item.label"
        mat-raised-button
        [color]="item.color"
        [matTooltip]="item.tooltip"
        [disabled]="disabled_elements.get(item.name)"
        [matMenuTriggerFor]="item.mat_menu"
      >
        <ng-container *ngTemplateOutlet="itemBody"></ng-container>
      </button>

      <button
        *ngSwitchCase="'menu'"
        #button
        class="button"
        [class.button--alt]="item.label"
        mat-raised-button
        [color]="item.color"
        [matTooltip]="item.tooltip"
        [disabled]="disabled_elements.get(item.name)"
        (click)="openMenu(button, item.menu)"
      >
        <ng-container *ngTemplateOutlet="itemBody"></ng-container>
      </button>

      <a
        *ngSwitchCase="'router_link'"
        class="button"
        [class.button--alt]="item.label"
        mat-raised-button
        [color]="item.color"
        [matTooltip]="item.tooltip"
        [routerLink]="item.url"
        [queryParams]="item.query_params"
      >
        <ng-container *ngTemplateOutlet="itemBody"></ng-container>
      </a>

      <a
        *ngSwitchCase="'href'"
        class="button"
        [class.button--alt]="item.label"
        mat-raised-button
        target="_blank"
        [href]="item.url"
        [matTooltip]="item.tooltip"
        [color]="item.color"
      >
        <ng-container *ngTemplateOutlet="itemBody"></ng-container>
      </a>

      <ng-template #itemBody>
        <mat-icon
          *ngIf="item.icon"
          class="button__icon"
          [color]="item.modificator"
        >
          {{ item.icon }}
        </mat-icon>

        <span
          *ngIf="item.label"
          class="button__text"
          [ngClass]="{
            'button__text--warn': item.modificator === 'warn',
            'button__text--single': !item.icon
          }"
        >
          {{ item.label }}
        </span>
      </ng-template>
    </ng-container>
  </div>
</div>
